

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CountdownV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

fixedDigits(num, digits = 2) {
    const numStr = num?.toString() ?? ''
    if (numStr.length < digits) {
      return `${'0'.repeat(digits - numStr.length)}${numStr}`
    } else {
      return numStr
    }
  }

  mount() {
    const countdown_opts = this.countdown_opts

    /* NOTE: This is duplicated from projects/lib/packages/yggdrasil-liquidjs/src/builtin/filters/date.ts */
    let countdownSpans = 0
    if(countdown_opts.show_years) countdownSpans |= countdown.YEARS
    if(countdown_opts.show_months) countdownSpans |= countdown.MONTHS
    if(countdown_opts.show_weeks) countdownSpans |= countdown.WEEKS
    if(countdown_opts.show_days) countdownSpans |= countdown.DAYS
    if(countdown_opts.show_hours) countdownSpans |= countdown.HOURS
    if(countdown_opts.show_minutes) countdownSpans |= countdown.MINUTES
    if(countdown_opts.show_seconds) countdownSpans |= countdown.SECONDS

    const now = new Date()
    let countToDate = now
    try{
      countToDate = new Date(this.end_date_time)
      if(isNaN(countToDate)) {
        countToDate = now
      }
      if(countToDate < now) {
        countToDate = now
      }
    } catch(e) {
      console.warn(`liiquid filter 'countdown': Invalid date '${this.end_date_time}'`)
    }

    var timerId =
      countdown(
        countToDate,
        (ts) => {
            if(ts.value < 0) {
              $('[data-time-type="Years"', this.element).find('span').text(ts.years)
              $('[data-time-type="Months"', this.element).find('span').text(ts.months)
              $('[data-time-type="Weeks"', this.element).find('span').text(ts.weeks)
              $('[data-time-type="Days"', this.element).find('span').text(ts.days)
              $('[data-time-type="Hours"', this.element).find('span').text(this.fixedDigits(ts.hours, 2))
              $('[data-time-type="Minutes"', this.element).find('span').text(this.fixedDigits(ts.minutes, 2))
              $('[data-time-type="Seconds"', this.element).find('span').text(this.fixedDigits(ts.seconds, 2))
            } else {
              $('[data-time-type="Years"', this.element).find('span').text("0")
              $('[data-time-type="Months"', this.element).find('span').text("0")
              $('[data-time-type="Weeks"', this.element).find('span').text("0")
              $('[data-time-type="Days"', this.element).find('span').text("0")
              $('[data-time-type="Hours"', this.element).find('span').text("00")
              $('[data-time-type="Minutes"', this.element).find('span').text("00")
              $('[data-time-type="Seconds"', this.element).find('span').text("00")
              clearInterval(timerId)

              if(this.timer_action === "showhide") {
                $(this.hide_ids.map((id) => `.${id}`).join(',')).hide()
                $(this.show_ids.map((id) => `.${id}`).join(',')).show()
              } else if(this.timer_action === "redirect_to") {
                if(this.redirect_to) {
                  window.location.href = this.redirect_to
                }                  
              }
            }
        },
        countdownSpans);
  }



}

registerComponent('Countdown/V1', CountdownV1)
window["CountdownV1"] = CountdownV1

